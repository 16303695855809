import React, { useState, useEffect } from 'react';
import './ProfileForm.css'; // Make sure to write appropriate CSS for the form

const ProfileForm = ({ handleSubmit, userProfile }) => {
  const [fullName, setFullName] = useState('');
  const [personalRequest, setPersonalRequest] = useState('');
  const [dayOfBirth, setDayOfBirth] = useState('');
  const [monthOfBirth, setMonthOfBirth] = useState('');
  const [yearOfBirth, setYearOfBirth] = useState('');
  const [gender, setGender] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [title, setTitle] = useState('');
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (userProfile.birthday) {
      const [year, month, day] = userProfile.birthday.split('-');
      setFullName(userProfile.fullname) 
      setGender(userProfile.gender)
      setDayOfBirth(day)
      setMonthOfBirth(month)
      setYearOfBirth(year)
      setPersonalRequest(userProfile.personal_request)
      setTitle('Edit Profile')
      setEditMode(true)
    } else {
      setTitle('Discover the Numbers That Shape Your Destiny ✨');
      setEditMode(false)
    }
  }, []);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Confirmación de alerta
    const isConfirmed = window.confirm(
      `You have entered your full name as "${fullName}". For better results, please confirm that this is your full name as it appears on your birth certificate`
    );

    if (!isConfirmed) {
      setIsSubmitting(false); 
      return; // No se envía el formulario si el usuario no confirma
    }

    setIsSubmitting(true);
    await handleSubmit({ fullName, dayOfBirth, monthOfBirth, yearOfBirth, personalRequest, gender });
    setIsSubmitting(false); 
  };

  const getMonth = (month) => {
    const m = {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December"
    };
    return m[month];
  };

  return (
    <div>
    <h3>{title}</h3>
    {!editMode && <p>Get my numerology</p>}
    <form className="birthdate-form" onSubmit={handleFormSubmit}>
      <input
        className="input-field"
        type="text"
        placeholder="Full Name (like your Birth Certificate)"
        value={fullName || userProfile.fullName}
        onChange={(e) => setFullName(e.target.value)}
        required
      />
      <select
        className="select-field"
        value={dayOfBirth}
        onChange={(e) => setDayOfBirth(e.target.value)}
        required
      >
        <option value="">Day</option>
        {/* Generate days */}
        {[...Array(31)].map((_, index) => (
          <option key={index} value={index + 1}>
            {index + 1}
          </option>
        ))}
      </select>
      <select
        className="select-field"
        value={monthOfBirth}
        onChange={(e) => setMonthOfBirth(e.target.value)}
        required
      >
        <option value="">Month</option>
        {/* You can replace these with actual month names */}
        {[...Array(12)].map((_, index) => (
          <option key={index} value={index + 1}>
            {getMonth(index + 1)}
          </option>
        ))}
      </select>
      <select
        className="select-field"
        value={yearOfBirth}
        onChange={(e) => setYearOfBirth(e.target.value)}
        required
      >
        <option value="">Year</option>
        {/* Generate years */}
        {[...Array(100)].map((_, index) => (
          <option key={index} value={new Date().getFullYear() - index}>
            {new Date().getFullYear() - index}
          </option>
        ))}
      </select>
      <select
          className="select-field"
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          required
        >
        <option value="">Gender</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="non-binary">Non-Binary</option>
      </select>

      {!editMode &&  <input
          className="input-field"
          type="text"
          placeholder="Share any specific concerns or areas of interest..."
          value={personalRequest}
          onChange={(e) => setPersonalRequest(e.target.value)}
          required
        />}

      <button
          type="submit"
          className={`submit-button-profile ${isSubmitting ? 'submitting' : ''}`}
          disabled={isSubmitting} // Disable button while submitting
        >
          {isSubmitting ? 'Saving ...' : 'Save'}
        </button>
    </form>
    </div>
    
  );
};

export default ProfileForm;
