import React from "react";
import "./Disclaimer.css";

function Disclaimer() {
  return (
    <div className="disclaimer-container">
    </div>
  );
}

export default Disclaimer;
