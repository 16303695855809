import "./NewChatButton.css";
import React, { useState, useEffect } from "react";
import ModalProfile from './ModalProfile';
import { getUser, updateUser } from "../Services/api";

const Profile = ({user, isOpenEditProfile, setIsOpenEditProfile, userProfile}) => {
    const [isModalOpen, setIsModalOpen] = useState(isOpenEditProfile);
    
    const openModal = () => {
      setIsOpenEditProfile(true)
      setIsModalOpen(true)
    }
    const closeModal = () => {
      setIsOpenEditProfile(false);
      setIsModalOpen(false);
    } 
    useEffect(() => {
        async function fetchProfile() {
            if (!localStorage.getItem('token')) {
              return
            }

            if (!userProfile.birthday || isOpenEditProfile) {
              openModal();
            }
        }
        fetchProfile();
    }, [isOpenEditProfile]);

  const handleSubmit = async (formData) => {
    var userProfile = {
      birthName: formData.fullName.trim(),
      birthDay: formData.dayOfBirth.trim(),
      birthMonth: formData.monthOfBirth.trim(),
      birthYear: formData.yearOfBirth.trim(),
      country: localStorage.getItem("country")
    }

    try {
      const response = await fetch('https://us-central1-optimal-moon-289301.cloudfunctions.net/n', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(userProfile)
      });
  
      if (response.ok) {
        const jsonResponse = await response.json();
        const numbers = jsonResponse.numbers
        userProfile.numerology = numbers
        userProfile.timestamp = Date.now();
      
        const userBody = {
          user: {
            fullname: formData.fullName.trim(),
            birthday: `${formData.yearOfBirth.trim()}-${formData.monthOfBirth.trim().padStart(2, '0')}-${formData.dayOfBirth.trim().padStart(2, '0')}`,
            numerology: numbers,
            country: localStorage.getItem("country"),
            hidden_agenda: numbers.agenda.number,
            life_path: numbers.lifepath.number,
            attitude: numbers.attitude.number,
            character: numbers.character.number,
            destiny: numbers.destiny.number,
            personality: numbers.personality.number,
            divine_purpose: numbers.purpose.number,
            soul_urge: numbers.soul.number,
            personal_request: formData.personalRequest.trim(),
            gender: formData.gender
          }
        };

        try {
          const responseUserUpdate = await updateUser(user.uid, userBody);
          const responseUserUpdateJson = responseUserUpdate.json()
          console.log(responseUserUpdateJson)
        } catch(e) {
          console.log(e)
        }

        window.location='/app'
      } else {
          throw new Error('Something went wrong with the server!');
      }
    } catch (error) {
        console.error('Error:', error);
    }  

    closeModal();
  }


  return (
    <ModalProfile isOpen={isModalOpen} userProfile={userProfile} handleClose={closeModal} handleSubmit={handleSubmit} />
  );
};

export default Profile;
