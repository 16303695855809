import "./Home.css";
import React from 'react';
import { useState, useEffect } from "react";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithRedirect,
    signInWithPopup,
    getRedirectResult,
    signOut,
    onAuthStateChanged
  } from "firebase/auth";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import GoogleAuthButtons from "../Components/GoogleAuthButtons";
import { Card, CardContent, Typography, Button, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // Using CheckCircleOutlineIcon for visibility

import LensIcon from '@mui/icons-material/Lens'; // Using LensIcon for a visible dot


import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import CheckIcon from '@mui/icons-material/Check';



const imageUrl = process.env.PUBLIC_URL + '/trueself.png';

const platform = process.env.PUBLIC_URL + '/platform1.png';

const know = process.env.PUBLIC_URL + '/know-yourself.png';

const all = process.env.PUBLIC_URL + '/allthis.png';


  const customerCount = 100; 
  const customerImages = [
    '/client1.webp',
    '/client2.webp',
    '/client3.webp',
    '/client4.webp',
    '/client5.webp', 
  ];


function Home({ app, db }) {
  const plans = [
    {
      name: 'Discover',
      price: '$0 usd',
      billingCycle: 'Free',
      description: [
        'Chat - Deep Understanding of Yourself (limited number of conversations and messages)',
        'Know all Numbers',
        'Personal Year Number',
        'Personal Month Number',
        'Personal Day Number',
        '📜 Numerology Interpretation',
        '🌟 Your Essence',
        '🤩 Passions & Interets',
        '🌿 How to heal?',
        'Explore numerology profiles for up to 2 individuals'
      ],
      signupText: 'Register',
      additional: '',
      buttonColor: '#6200ea' 
    },
    {
      name: 'Unlock Yourself',
      price: '$10 usd',
      billingCycle: 'One payment',
      description: [
        'Chat - Deep Understanding of Yourself (limited number of conversations and messages)',
        '💼 Professional and Careers',
        '💰 Wealth',
        '❤️ Love',
        '🔍 Who really am I?',
        '🚀 Life Purpose',
        '😌 Emotional Stability',
        '⚡ Potential Areas',
        '💖 Your IKIGAI',
        '🤩 Superpowers',
        '🧐 You need to focus on this.',
        '🚫 Avoid Focusing on This',
        '😣 Read in Difficult Moments',
        '🌈 How to Manifesting Material Things?',
        '🎬 Youtube Channels',
        '📖 Books to read',
        '🎵 Music',
        'Analyze numerology insights for up to 10 different people',
      ],
      signupText: 'Register',
      additional: 'Everything in Discover, plus',
      buttonColor: '#6200ea' 
    },
    {
      name: 'Master Your Destiny',
      price: '$20 usd/mo ',
      billingCycle: 'Billed yearly. Pay monthly',
      description: [
        'Chat - Deep Understanding of Yourself (Unlimited)',
        'Request a Custom Category',
        'Enjoy the upcoming features'
      ],
      signupText: 'Register',
      additional: 'Everything in Unlock Yourself, plus',
      buttonColor: '#6200ea'
    },
    // {
    //   name: 'Insight Collective',
    //   price: 'Talk To',
    //   billingCycle: 'Billed yearly. Pay monthly',
    //   description: [
    //     'Chat with your Insights, Deep Understand Yourself and others.',
    //     'Gain unlimited access to comprehensive numerology profiles for an unlimited number of individuals',
    //     'Compare multiple profiles',
    //     'Enjoy the upcoming features',
    //     'Premium Support'
    //   ],
    //   signupText: 'Register',
    //   additional: 'Everything in Unlock Yourself, plus',
    //   buttonColor: '#6200ea'
    // }
  ];
  
  const PricingTable = () => {
    return (
      <Grid container spacing={2} justifyContent="center" sx={{ padding: 2 }} className="pricing">
        {plans.map((plan) => (
          <Grid item key={plan.name} xs={12} md={4} lg={4}>
            <Card raised sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minHeight: 600,
                maxWidth: 350,
                backgroundColor: '#fff',
                borderRadius: 3,
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
              }}>
                <CardContent>
                  <Typography variant="h5" component="div" sx={{ color: '#333', fontWeight: 'bold', marginBottom: 2 }}>
                    {plan.name}
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: 24, color: '#555555', marginBottom: 1, fontWeight: 400}}>
                    {plan.price}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: 16, color: '#999', marginBottom: 2 }}>
                    {plan.billingCycle}
                  </Typography>
                  <Button onClick={handleLogin} variant="contained" sx={{ marginTop: 2, maxWidth: 350, maxHeight: 50, width: '100%', backgroundColor: '#6200ea', padding: '15px 0', textTransform: 'none', fontSize: '1.1rem', borderRadius: 3}}>
                  {plan.signupText}
                </Button>
                  <List style={{marginTop: 30}}>
                    {plan.description.map((feature, index) => (
                      <ListItem key={index} disablePadding sx={{ marginBottom: 1 }}>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon sx={{ color: '#6200ea', fontSize: 'large' }} />
                        </ListItemIcon>
                        <ListItemText primary={feature} primaryTypographyProps={{ fontSize: '1rem', color: '#666' }} />
                      </ListItem>
                    ))}
                    {plan.additional && (
                      <Typography variant="body2" sx={{ color: '#333', marginTop: 1, fontStyle: 'italic' }}>
                        {plan.additional}
                      </Typography>
                    )}
                  </List>
                </CardContent>
                
              </Card>
          </Grid>
        ))}
      </Grid>
    );
  }
  



  const [user, setUser] = useState(null);
  const provider = new GoogleAuthProvider(app);
  const auth = getAuth(app);
  const firestoreDB = getFirestore(app);
  const [authLoading, setAuthLoading] = useState(true);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const [isRateLimited, setIsRateLimited] = useState(false);

  // Redirect user to google login
  function handleLogin() {
    setIsLoggingIn(true);
    signInWithPopup(auth, provider);
    getRedirectResult(auth)
      .then(() => {
        console.log("Signed In");
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  // Log user out, clear old messages and input
  function handleLogout() {
    signOut(auth)
      .then(() => {
        console.log("Logged out");
        localStorage.removeItem('login')
        localStorage.removeItem('country')
        setIsLoading(false);
        setIsRateLimited(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    // When the authentication state changes (user logging in/out)
    onAuthStateChanged(auth, (user) => {        
        setAuthLoading(false);
        setUser(user);
        localStorage.setItem('login', Date())
        if (user) {
            window.location ='/app'
        }
    });
  }, []);

  return (
    <>
        <header className="header" id="header">
            <nav className="navbar container">
                <div className="brand">
                    <span>963.ai</span>
                </div>
                <div className='absolutePosition'>
                  {!user && !authLoading && (
                              <button className="btn btn-darken btn-inline" onClick={handleLogin}>
                                  Register <i className="bx bx-right-arrow-alt"></i>
                              </button>
                          )}
                  {user && (<button className="btn btn-darken btn-inline" onClick={handleLogout}>
                              Logout <i className="bx bx-right-arrow-alt"></i>
                          </button>)}
                  {!user && !authLoading && (<a onClick={handleLogin} className="menu-block">Login</a>)}
                  {user && (<a onClick={handleLogout} className="menu-block">Logout</a>)}  

                </div> 
            </nav>
        </header>
        <main className="main">
            <section className="section banner banner-section">
                <div className="container banner-column">
                    <img className="banner-image" src={imageUrl} alt="banner" />
                    <div className="banner-inner">
                        <h1 className="heading-xl">Discover Your True Purpose with AI-Powered Numerology</h1>
                        <p className="paragraph">
                        Unleash your potential and live your best life with insights into your true self powered by AI, discover who you really are. Join the 1% who have found true purpose.</p>
                        {/* <p className="paragraph special-p">
                        In today’s fast-paced world, AI technology can be your guide to uncovering your true essence and living authentically. Discover how our AI-driven numerology can help you find your unique path and purpose.
                        </p> */}
                        <p className="paragraph special-p">Unlock your true essence with our AI technology. Discover your unique path, find purpose, and gain deep insights to live authentically and free from anxiety, depression, and other mental challenges that arise from not knowing your true self.</p>
                        <div className="customer-reviews">
                          <div className="customer-images">
                            {customerImages.map((image, index) => (
                              <img key={index} src={process.env.PUBLIC_URL + image} alt={`Customer ${index + 1}`} />
                            ))}
                            </div>
                            <div className="rating">
                              <span className="stars">★★★★★</span> {/* Replace with a star component or images if needed */}
                              <span className="text">from {customerCount} happy souls</span>
                            </div>
                            <div className="heart">
                              <span>❤️</span> {/* This could be an image or an icon from a library like FontAwesome */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="platform">
              <div>
                <h2 className="platform-h2">Unlock Your Potential, Shape Your Destiny by Discovering Your True Self. 🚀 ✨</h2>
                
                <img className="banner-platform" style={{margin: 'auto', maxWidth: 450}} src={platform} alt="963.ai"/>
                <p style={{color: 'black', marginTop: 40, fontSize: 22}}>Join the journey of self-discovery and unlock the secrets of the universe with the ancient wisdom of numerology.</p>
                
                {!user && !authLoading && (
                      <button style={{marginTop:50, marginBottom:80}}className="btn btn-darken btn-inline" onClick={handleLogin}>
                          Register Now<i className="bx bx-right-arrow-alt"></i>
                      </button>
                  )}
              </div>
              
            </section>

            <section className="platform" style={{paddingTop: 0}}> 
              <div>
                <h2 className="platform-h2">Know & Unlock Yourself ✨</h2>
                
                <img className="banner-platform" style={{margin: 'auto'}} src={know} alt="963.ai"/>
                <p style={{color: 'black', marginTop: 40, fontSize: 22}}>Understand deep insights about yourself that you didn't know, what you are good at, what you stand out for, what you were born for, what your purpose is and more....</p>
                
                {!user && !authLoading && (
                      <button style={{marginTop:50, marginBottom:80}}className="btn btn-darken btn-inline" onClick={handleLogin}>
                          I'm going to transform my life<i className="bx bx-right-arrow-alt"></i>
                      </button>
                  )}
              </div>
              
            </section>


            <section className="platform" style={{paddingTop: 0}}> 
              <div>
                <h2 className="platform-h2">Understand your Essence 🌟</h2>
                
                <img className="banner-platform" style={{margin: 'auto'}} src={all} alt="963.ai"/>
                <p style={{color: 'black', marginTop: 40, fontSize: 22}}>All this to help you unlock your mind to be part of the top 1%.</p>
                
                {!user && !authLoading && (
                      <button style={{marginTop:50, marginBottom:80}}className="btn btn-darken btn-inline" onClick={handleLogin}>
                          I desire to unlock my mind <i className="bx bx-right-arrow-alt"></i>
                      </button>
                  )}
              </div>
              
            </section>

            <section className="platform"> 
              <PricingTable/>
            </section>

            <footer className="footer">
              <div className="container footer-container">
                <div className="footer-brand">
                  <span>hi@963.ai</span>
                </div>
                <div className="footer-nav">
                  {/* <a href="#about" className="footer-link">About</a>
                  <a href="#services" className="footer-link">Services</a>
                  <a href="#contact" className="footer-link">Contact</a> */}
                </div>
                <div className="footer-socials">
                  {/* Icons would be replaced with actual images or icon components */}
                  <a target="_blank" href="https://www.youtube.com/channel/UCDQICQK8TWjK3hcx6iQA2Ug" className="footer-icon" rel="noreferrer">YT</a>
                  {/* <a href="https://twitter.com" className="footer-icon">TW</a> */}
                  <a target="_blank" href="https://instagram.com/963ai_" className="footer-icon" rel="noreferrer">IG</a>
                </div>
                <div className="footer-legal">
                  <span>Made with ❤️ by ADDABRA LLC © 2024</span>
                </div>
              </div>
            </footer>
        </main>
       
    </>
  );
}

export default Home;
