import React, { useState } from 'react';
import Person from './PersonForm'; // Import the form component
import './ModalPerson.css'; // Make sure you have the CSS for the modal

const ModalPerson = ({ isOpen, handleClose, handleSubmit}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={handleClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close-button" onClick={handleClose}>×</button>
        <Person  handleSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default ModalPerson;