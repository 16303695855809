import React, { Suspense } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainPage from "./Pages/MainPage";
import { CircularProgress } from "@mui/material";
import Home from "./Pages/Home";
import ChatPage from "./Pages/ChatPage";

const Error404Page = React.lazy(() => import("./Pages/Error404Page"));
const StorePage = React.lazy(() => import("./Pages/StorePage"));

const firebaseConfig = {
  apiKey: "AIzaSyClF-6QhCBqy6xb1hsxZ9U5JsDrNT6PMRc",
  authDomain: "ai-95449.firebaseapp.com",
  projectId: "ai-95449",
  storageBucket: "ai-95449.appspot.com",
  messagingSenderId: "437527690722",
  appId: "1:437527690722:web:ac061bf345de62a5a7cdbb",
  measurementId: "G-90NC6J29E4",
  databaseURL: "https://ai-95449-default-rtdb.firebaseio.com/"
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);

if (process.env.NODE_ENV !== "test") {
  const analytics = getAnalytics(app);
}

function App() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <CircularProgress
            style={{
              margin: 0,
              position: "absolute",
              top: "50%",
              left: "50%",
              color: "#b3befe",
            }}
          />
        }
      >
        <Routes>
          <Route path="/" element={<Home app={app} db={db} />} />
          <Route path="/app" element={<MainPage app={app} db={db} />} />
          <Route path="/chat" element={<ChatPage app={app} db={db} />} />
          <Route path="/chat/:uid" element={<ChatPage app={app} db={db} />} />
          <Route path="/store" element={<StorePage app={app} />} />
          <Route path="*" element={<Error404Page />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
