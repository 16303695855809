import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, TextField, List, ListItem, IconButton, InputAdornment, Paper, CircularProgress } from '@mui/material';
import { Send as SendIcon, AttachFile as AttachFileIcon, Mic as MicIcon, PlayArrow as PlayArrowIcon, Stop as StopIcon } from '@mui/icons-material';
import { sendMessage, listMessages } from '../Services/api';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useParams } from 'react-router-dom';

const ChatAI = ({ getChats, userProfile }) => {
  const { uid } = useParams();
  const messagesEndRef = useRef(null);

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [conversationUID, setConversationUID] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [currentUtterance, setCurrentUtterance] = useState(null);
  const [playingMessage, setPlayingMessage] = useState(null);

  const recognitionRef = useRef(null);

  useEffect(() => {
    if (window.SpeechRecognition || window.webkitSpeechRecognition) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = false;
      recognitionRef.current.interimResults = false;
      recognitionRef.current.lang = navigator.language || 'en-US';

      recognitionRef.current.onresult = (event) => {
        setInput(event.results[0][0].transcript);
        setIsListening(false);
      };

      recognitionRef.current.onerror = (event) => {
        console.error('Speech recognition error', event);
        setIsListening(false);
      };
    }
  }, []);

  useEffect(() => {
    if (uid) {
      setMessages([]);
      setConversationUID(uid);
      setConversation(uid);
      setIsFetching(true);
    }
  }, [uid]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const setConversation = async (uid) => {
    if (!localStorage.getItem('token')) {
      return;
    }

    try {
      setIsFetching(true);
      const request = await listMessages(uid);
      const messagesData = await request.json();
      setMessages(messagesData.data.entries);
      setIsFetching(false);
    } catch (e) {
      setIsFetching(false);
      console.log(e);
    }
  };

  const sendMessages = async () => {
    if (input.trim()) {
      const message = {
        message: {
          role: 'user',
          type: 'text',
          content: input,
        },
      };

      if (uid || conversationUID) {
        message.message.chat_uid = uid || conversationUID;
      }

      setInput('');
      setMessages((prevMessages) => [...prevMessages, message.message]);

      try {
        setIsFetching(true);
        const request = await sendMessage(message);
        const response = await request.json();
        setMessages(response.data.entries);
        setIsFetching(false);

        if (!message.message.chat_uid) {
          debugger;
          setConversationUID(response.data.entries[0].chat_uid);
          getChats(userProfile.auth_uid);
        }
      } catch (e) {
        setIsFetching(false);
        console.log(e);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessages();
    }
  };

  const handleSpeech = () => {
    if (isListening) {
      recognitionRef.current.stop();
      setIsListening(false);
    } else {
      recognitionRef.current.start();
      setIsListening(true);
    }
  };

  const readMessage = (message, index) => {
    if ('speechSynthesis' in window) {
      if (currentUtterance && playingMessage === index) {
        window.speechSynthesis.cancel();
        setPlayingMessage(null);
        setCurrentUtterance(null);
      } else {
        if (currentUtterance) {
          window.speechSynthesis.cancel();
        }
        const utterance = new SpeechSynthesisUtterance(message);
        setCurrentUtterance(utterance);
        setPlayingMessage(index);
        utterance.onend = () => {
          setPlayingMessage(null);
          setCurrentUtterance(null);
        };
        window.speechSynthesis.speak(utterance);
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '95vh',
        maxWidth: 'auto',
        margin: 'auto',
        borderRadius: 2,
        p: 2,
        background: 'linear-gradient(135deg, #4b2e83 30%, #916BBF 90%)',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        color: 'white',
        marginTop: 8.1,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          mb: 2,
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }}
      >
        {isFetching && <CircularProgress style={{ top: 50, color: '#b3befe' }} />}
        <List>
          {messages.map((message, index) => (
            <ListItem
              key={index}
              sx={{ justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start' }}
            >
              <Box
                sx={{
                  bgcolor: message.role === 'user' ? '#8158F6' : '#333',
                  color: 'white',
                  p: 1,
                  borderRadius: 2,
                  maxWidth: '70%',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Markdown remarkPlugins={[remarkGfm]}>{message.content}</Markdown>
                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                  <IconButton
                    onClick={() => readMessage(message.content, index)}
                    color={playingMessage === index ? 'secondary' : 'default'}
                  >
                    {playingMessage === index ? <StopIcon sx={{ color: '#FF4136' }} /> : <PlayArrowIcon sx={{ color: 'white' }} />}
                  </IconButton>
                </Box>
              </Box>
            </ListItem>
          ))}
          <div ref={messagesEndRef} />
        </List>
      </Paper>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          fullWidth
          variant="outlined"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Ask a question about yourself ..."
          disabled={isFetching}
          InputProps={{
            style: { color: 'white', backgroundColor: '#444' },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={sendMessages}>
                  <SendIcon sx={{ color: 'white' }} />
                </IconButton>
                <IconButton edge="end" onClick={handleSpeech} color={isListening ? 'primary' : 'default'}>
                  <MicIcon sx={{ color: isListening ? '#8158F6' : 'white' }} />
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
              },
              '& .MuiInputBase-root': {
                borderRadius: 2,
              },
              '& .MuiInputBase-input::placeholder': {
                fontSize: '14.5px',
                color: 'rgba(255, 255, 255, 0.7)',
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default ChatAI;
