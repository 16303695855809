import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import ChatInputForm from "../Components/ChatInputForm";
import ChatMessage from "../Components/ChatMessage";
import NewPersonButton from "../Components/NewPersonButton";
import Hero from "../Components/Hero";
import GoogleAuthButtons from "../Components/GoogleAuthButtons";
import MessageHistory from "../Components/MessageHistory";
import BugReportModal from "../Components/BugReportModal";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  getRedirectResult,
  signOut,
  onAuthStateChanged
} from "firebase/auth";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { ref, push, get, set, update, increment } from "firebase/database";
import StoreButton from "../Components/StoreButton";
import "./MainPage.css";
import Disclaimer from "../Components/Disclaimer";
import TemperatureSlider from "../Components/TemperatureSlider";
import SignUpHeading from "../Components/SignUpHeading";
import NotSubscribedHeading from "../Components/NotSubscribedHeading";
import FreeTrial from "../Components/FreeTrial";
import ClearConversations from "../Components/ClearConversations";
import RateLimitError from "../Components/RateLimitError";
import ResponseFailedError from "../Components/ResponseFailedError";
import ModeChanger from "../Components/ModeChanger";
import CodingModeGuide from "../Components/CodingModeGuide";
import localStorage from 'localStorage'
import { makeStyles } from '@mui/styles';
import { Typography, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material';
import React from 'react';


import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';



import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Profile from "../Components/Profile";
import { authUserService, getCategories, getCategory, getUser, listPersons } from "../Services/api";
import EditProfileButton from "../Components/EditProfileButton";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Persons from "../Components/Persons";




type Anchor = 'top' | 'left' | 'bottom' | 'right';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  listItemAvatar: {
    minWidth: 'auto',
  },
  productTitle: {
    fontWeight: 'bold',
  },
  productPrice: {
    fontWeight: 'bold',
  },
}));






function MainPage({ app, db }) {
  const provider = new GoogleAuthProvider(app);
  const auth = getAuth(app);
  const firestoreDB = getFirestore(app);

  const [user, setUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [personalYear, setPersonalYear] = useState(null);
  const [personalMonth, setPersonalMonth] = useState(null);
  const [personalDay, setPersonalDay] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [hasTrial, setHasTrial] = useState(false);
  const [isRateLimited, setIsRateLimited] = useState(false);
  const [responseFailed, setResponseFailed] = useState(false);
  const [selectedModel, setSelectedModel] = useState("text-davinci-003");
  const [bestProducts, setBestProducts] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [messagesHistory, setMessagesHistory] = useState([]);
  const [isOpenEditProfile, setIsOpenEditProfile] = useState(false);
  const [userCategories, setUserCategories] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState({});

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  useEffect(() => {
    async function fetchCountry() {
      if (!localStorage.getItem('country')) {
        try {
          const ipdata = await fetch('https://ipinfo.io/json?token=59fbca3b6ead5d');
          const jsonResponse = await ipdata.json();
          localStorage.setItem('country', jsonResponse.country);
        } catch (error) {
          console.log(error);
        }
      }
    }
    // When the authentication state changes (user logging in/out)
    onAuthStateChanged(auth, (user) => {
      setIsLoadingPage(true)
      setAuthLoading(false);
      setUser(user); 

      if (!localStorage.getItem('token') && !isFetching) {
        setIsFetching(true)
        fetchCountry();
        authUser(user);
      }
      
      getUserProfile(user.uid);            
      loadSubscription();
      handleTrialPeriod(user);
      checkTrialExpired(user);
  });  
  }, []);


  const authUser = async (user) => {
    const body = {
      email: user.email,
      auth_uid: user.uid
    };
   try {
    const response = await authUserService(body)
    const res = await response.json()
    localStorage.setItem('user_id', res.data.user.id)
    localStorage.setItem('token', res.data.token)
    
    getUserProfile(user.uid);
   } catch(e) {
      console.log(e);
   }
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  function reduceToSingleDigit(number) {
    while (number > 9) {
      number = number.toString().split('').map(Number).reduce((a, b) => a + b, 0);
    }
    return number;
  }
  
  function getYearNumber(day, month) {
    const currentYear = new Date().getUTCFullYear();
    const reducedDay = reduceToSingleDigit(day);
    const reducedMonth = reduceToSingleDigit(month);
    const reducedYear = reduceToSingleDigit(currentYear);
  
    const personalYear = reduceToSingleDigit(reducedDay + reducedMonth + reducedYear);
    return personalYear;
  }
  
  function getMonthNumber(personalYear) {
    const currentMonth = new Date().getUTCMonth() + 1; // Months are 0-indexed in JavaScript
    const personalMonth = reduceToSingleDigit(personalYear + currentMonth);
    return personalMonth;
  }
  
  function getPersonalDay(personalMonth) {
    const currentDay = new Date().getUTCDate();
    const personalDay = reduceToSingleDigit(personalMonth + currentDay);
    return personalDay;
  }

  const getEmoticonByCategory = (category) => {
    const emoticons = {
      lifepath: '👣',
      destiny: '✨',
      purpose: '🌟',
      personality: '😎',
      attitude: '💁',
      character: '🎭',
      soul: '❤️',
      agenda: '🕵️'
    };
  
    // Convert the category to lowercase to ensure case-insensitive matching
    const key = category.toLowerCase();
  
    // Return the emoticon or a placeholder if the category is not found
    return emoticons[key] || '❓';
  }


  const getEmoticonsMeaning = (category, number) => {
    const emoticons = {
      "lifepath": {
        "1": {
          "emoticons": "🌟 🚀 👑 🔥",
          "keywords": ["leadership", "pioneering", "authority", "passion"]
        },
        "2": {
          "emoticons": "🤝 🕊️ ⚖️ 💞",
          "keywords": ["partnership", "peace", "harmony", "diplomacy", "balance", "love", "connection"]
        },
        "3": {
          "emoticons": "🖌️ 💬 🎭 🎨",
          "keywords": ["creativity", "artistic expression", "communication", "emotional expression", "drama", "variety"]
        },
        "4": {
          "emoticons": "🧱 📐 🌳 🏰",
          "keywords": ["stability", "structure", "growth", "security"]
        },
        "5": {
          "emoticons": "🌍✈️ 🗺️ 🎢 🕺",
          "keywords": ["freedom", "adventure", "change", "dynamic", "exploration"]
        },
        "6": {
          "emoticons": "❤️ 🌺 🏡 👪",
          "keywords": ["care", "nurturing", "responsibility", "family", "support"]
        },
        "7": {
          "emoticons": "🧘 📚 🔮 ⭐",
          "keywords": ["insight", "spirituality", "knowledge", "mysticism", "guidance"]
        },
        "8": {
          "emoticons": "💼 💰 🏆 ♾️",
          "keywords": ["power", "success", "abundance", "achievement", "potential"]
        },
        "9": {
          "emoticons": "🌍 🤲 🌈 🕊️",
          "keywords": ["humanitarianism", "compassion", "completion", "global awareness", "peace"]
        },
        "11": {
          "emoticons": "🌠 💡 👁️ ⚡",
          "keywords": ["intuition", "spiritual awakening", "high energy", "inspiration", "vision"]
        },
        "22": {
          "emoticons": "🏗️ 🌐 🔑 📈",
          "keywords": ["master builder", "manifestation", "vision", "global impact", "growth"]
        }
      },
      "destiny": {
        "1": {
          "emoticons": "👑 🚀 💼",
          "keywords": ["leader", "independent", "ambitious", "innovate"]
        },
        "2": {
          "emoticons": "🤝 🕊️ 💭",
          "keywords": ["diplomat", "sensitivity", "intuition", "partnerships", "diplomacy"]
        },
        "3": {
          "emoticons": "🎤 🎨 ✍️",
          "keywords": ["communicator", "creative", "expressive", "inspire", "art", "communication"]
        },
        "4": {
          "emoticons": "🛠️ 🏗️ 📐",
          "keywords": ["builder", "practical", "determined", "foundation"]
        },
        "5": {
          "emoticons": "🌍 ✈️ 🗺️",
          "keywords": ["adventurer", "versatile", "dynamic", "exploration"]
        },
        "6": {
          "emoticons": "❤️ 🏡 👨‍👩‍👧",
          "keywords": ["caregiver", "compassionate", "responsible", "nurture", "support"]
        },
        "7": {
          "emoticons": "🔍 📚 🧠",
          "keywords": ["thinker", "intellectual", "analytical", "research", "spirituality", "science"]
        },
        "8": {
          "emoticons": "💼 💰 🏆",
          "keywords": ["executive", "powerful", "driven", "success", "business", "wealth"]
        },
        "9": {
          "emoticons": "🌍 🤲 ❤️",
          "keywords": ["humanitarian", "compassionate", "selfless", "serve", "make a difference"]
        },
        "11": {
          "emoticons": "💫 🙏 🌎",
          "keywords": ["master healer", "intuitive", "spiritual", "inspire", "global"]
        },
        "22": {
          "emoticons": "🌐 🏗️ ✨",
          "keywords": ["master builder", "vision", "practicality", "lasting legacies"]
        }
      },
      "purpose": {
        "1": {
          "emoticons": "🌟 🛠️ 💡",
          "keywords": ["pioneer", "innovation", "foundation", "concept"]
        },
        "2": {
          "emoticons": "🕊️ 💖 🤝",
          "keywords": ["peace", "comfort", "harmony"]
        },
        "3": {
          "emoticons": "🎨 ✍️ 🎬",
          "keywords": ["express", "creatively", "art", "writing", "film"]
        },
        "4": {
          "emoticons": "🏗️ ❤️ 🛤️",
          "keywords": ["stable", "humanitarian", "support"]
        },
        "5": {
          "emoticons": "⚡ 🌍 🔜",
          "keywords": ["progressive change", "betterment", "future generations"]
        },
        "6": {
          "emoticons": "👩‍🏫 ❤️ 🌱",
          "keywords": ["teach", "nurture", "support", "humanitarian", "family"]
        },
        "7": {
          "emoticons": "📚 💭 🎨",
          "keywords": ["knowledge", "creative", "impact", "science", "art", "philosophy"]
        },
        "8": {
          "emoticons": "👑 🤲 💸",
          "keywords": ["leadership", "philanthropy", "helping"]
        },
        "9": {
          "emoticons": "🌍 💞 🤝",
          "keywords": ["serve humanity", "philanthropist", "leader", "caregiver"]
        },
        "11": {
          "emoticons": "💫 🙏 💡",
          "keywords": ["uplift", "spiritually", "guidance", "wisdom"]
        },
        "22": {
          "emoticons": "🌆 🛠️ 🌍",
          "keywords": ["build", "organize", "enterprises", "community", "world"]
        }
      },
      "personality": {
        "1": {
          "emoticons": "💥 💪 👑",
          "keywords": ["dynamic", "determined", "leader"]
        },
        "2": {
          "emoticons": "🕊️ ❤️ 🪨",
          "keywords": ["peacemaker", "heart of gold", "rock"]
        },
        "3": {
          "emoticons": "🌟 😄 🎉",
          "keywords": ["creative", "fun", "light up"]
        },
        "4": {
          "emoticons": "🛡️ 💼 🤝",
          "keywords": ["dedicated", "trustworthy", "integrity"]
        },
        "5": {
          "emoticons": "💫 🚀 😎",
          "keywords": ["vibrant", "inspiring", "infectious"]
        },
        "6": {
          "emoticons": "❤️ 🏡 👨‍👩‍👧‍👦",
          "keywords": ["caring", "responsibility", "love"]
        },
        "7": {
          "emoticons": "🔍 📚 🧐",
          "keywords": ["analytical", "curious", "knowledge"]
        },
        "8": {
          "emoticons": "👑 ⚖️ 🚀",
          "keywords": ["ambitious", "just", "leadership"]
        },
        "9": {
          "emoticons": "🎨 ❤️ 🤲",
          "keywords": ["creative", "compassionate", "helping"]
        },
        "10": {
          "emoticons": "🌤️ 🏹 😊",
          "keywords": ["independent", "optimistic", "silver lining"]
        },
        "11": {
          "emoticons": "💖 💫 🙏",
          "keywords": ["sensitive", "inspiring", "support"]
        },
        "12": {
          "emoticons": "😉 🌟 🎯",
          "keywords": ["charming", "determined", "reality"]
        },
        "13": {
          "emoticons": "🛠️ 🧭 💪",
          "keywords": ["practical", "honest", "perseverance"]
        },
        "14": {
          "emoticons": "💡 🏃‍♂️ 🔥",
          "keywords": ["inventive", "energetic", "ideas"]
        },
        "15": {
          "emoticons": "🎨 ⚖️ ✊",
          "keywords": ["creative", "just", "fairness"]
        },
        "16": {
          "emoticons": "🧠 🕵️‍♂️ 📚",
          "keywords": ["intellectual", "reflective", "knowledge"]
        },
        "17": {
          "emoticons": "🌟 💼 😎",
          "keywords": ["ambitious", "energetic", "vitality", "class"]
        },
        "18": {
          "emoticons": "🌞 ❤️ 🤲",
          "keywords": ["powerful", "compassionate", "aiding"]
        },
        "19": {
          "emoticons": "🚵 💪 🌍",
          "keywords": ["adventurous", "dedicated", "spirit"]
        },
        "20": {
          "emoticons": "🧡 🙌 🎁",
          "keywords": ["warm-hearted", "helpful", "blessing"]
        },
        "21": {
          "emoticons": "😊 🎨 🎭",
          "keywords": ["optimistic", "creative", "beauty", "entertainment"]
        },
        "22": {
          "emoticons": "🏛️ 💖 🌌",
          "keywords": ["disciplined", "sensitive", "groundbreaking"]
        },
        "23": {
          "emoticons": "❤️ 💡 🌱",
          "keywords": ["nurturing", "inventive", "brilliant"]
        },
        "24": {
          "emoticons": "😉 ❤️ 🧠",
          "keywords": ["charming", "conscientious", "love", "wit"]
        },
        "25": {
          "emoticons": "🦉 ❤️ 🎁",
          "keywords": ["wise", "generous", "compassion"]
        },
        "26": {
          "emoticons": "👑 💡 ❤️",
          "keywords": ["commanding", "intuitive", "leadership", "inspiring"]
        },
        "27": {
          "emoticons": "🧳 🌍 💡",
          "keywords": ["old soul", "travel", "change", "insight"]
        },
        "28": {
          "emoticons": "😊 🕊️ 🏆",
          "keywords": ["charming", "diplomatic", "ideals", "achievement"]
        },
        "29": {
          "emoticons": "😌 💫 ❤️",
          "keywords": ["gentle", "charismatic", "intuitive", "comfort"]
        },
        "30": {
          "emoticons": "🎭 🎨 😄",
          "keywords": ["witty", "talented", "creativity"]
        },
        "31": {
          "emoticons": "💡 📚 🤝",
          "keywords": ["innovative", "knowledgeable", "reliable", "friend"]
        }
      },
      "attitude": {
        "1": {
          "description": "Naturally strong-willed and independent, you resist being told what to do.",
          "emoticons": "👊 🛣️ 👑 🚫",
          "keywords": ["strong-willed", "independent", "leadership", "resistance"]
        },
        "2": {
          "description": "Innately kind and nurturing, you are sensitive and cherish harmony.",
          "emoticons": "🤗 🕊️ 💖 🎶",
          "keywords": ["kindness", "nurturing", "sensitive", "harmony"]
        },
        "3": {
          "description": "Initially shy, you blossom into a captivating and adventurous spirit.",
          "emoticons": "🌱 🎭 🌺 🌟",
          "keywords": ["shy", "blossoming", "captivating", "adventurous"]
        },
        "4": {
          "description": "Sensitive yet stubborn, praise brings out your best qualities.",
          "emoticons": "🌼 🐢 👏 🛠",
          "keywords": ["sensitive", "stubborn", "praise", "quality"]
        },
        "5": {
          "description": "Full of boundless energy, you thrive on stimulation and detest delays.",
          "emoticons": "⚡ 🏃 💥 🚫⌚",
          "keywords": ["energy", "thrive", "stimulation", "impatience"]
        },
        "6": {
          "description": "Born responsible and caring, you show deep kindness to all around you.",
          "emoticons": "👶 ❤️ 🛡️ 😊",
          "keywords": ["responsible", "caring", "kindness", "empathy"]
        },
        "7": {
          "description": "With a brilliant mind, you crave solitude for your intellectual pursuits.",
          "emoticons": "🧠 🤫 📚 🌌",
          "keywords": ["brilliant", "solitude", "intellectual", "pursuits"]
        },
        "8": {
          "description": "Aiming for excellence, control makes you shine, especially with support.",
          "emoticons": "🎯 🎖️ 🔒 👥",
          "keywords": ["excellence", "control", "shine", "support"]
        },
        "9": {
          "description": "Mature from a young age, close encouragement drives your success.",
          "emoticons": "🧓 🌟 📈 💬",
          "keywords": ["mature", "encouragement", "success", "drive"]
        },
        "11": {
          "description": "Extremely sensitive and intuitive, inspired by music and praise, you're destined for greatness.",
          "emoticons": "💖 🎵 🌈 🌠",
          "keywords": ["sensitive", "intuitive", "inspired", "greatness"]
        },
        "22": {
          "description": "Though stubborn, with the right push, you become a driven and creative force.",
          "emoticons": "🐂 ➡️ 🚀 🎨",
          "keywords": ["stubborn", "motivation", "driven", "creative"]
        }
      },
      "character": {
        "1": {
          "emoticons": "💡 🎯 👑 🚀",
          "keywords": ["innovative", "goal-driven", "leader"]
        },
        "2": {
          "emoticons": "🕊️ 💬 👫 🤗",
          "keywords": ["diplomatic", "friendly", "peacekeeper"]
        },
        "3": {
          "emoticons": "😁 🖌️ 🎨 🌈",
          "keywords": ["optimistic", "creative", "captivating", "inspiring"]
        },
        "4": {
          "emoticons": "🔒 🗃️ 🧱 🛠️",
          "keywords": ["reliable", "organized", "dependable", "indispensable"]
        },
        "5": {
          "emoticons": "🌟 🎤 🕶️ 💃",
          "keywords": ["charming", "trendy", "influencer", "mission"]
        },
        "6": {
          "emoticons": "❤️ 🌍 🤲 💊",
          "keywords": ["humanitarian", "caring", "excel", "nurturing"]
        },
        "7": {
          "emoticons": "📚 🔍 🎓 🧠",
          "keywords": ["intellectually curious", "reserved", "teacher", "influential"]
        },
        "8": {
          "emoticons": "⚖️ 👁️ 🏛️ 💼",
          "keywords": ["authority", "insightful", "just", "decisive"]
        },
        "9": {
          "emoticons": "🤲 💔 🌎 🕊️",
          "keywords": ["humanitarian spirit", "service", "needed", "compassion"]
        },
        "11": {
          "emoticons": "🌟 🎤 💖 📸",
          "keywords": ["empathetic", "spotlight", "illuminating", "presence"]
        },
        "22": {
          "emoticons": "🏗️ 🌐 🔗 ✨",
          "keywords": ["visionary", "builder", "dedicated", "transformative"]
        }
      },
      "soul": {
        "1": {
          "emoticons": "💡 ❤️ 🌈 🛤️",
          "keywords": ["innovate", "inspire", "compassion", "understanding"]
        },
        "2": {
          "emoticons": "🕊️ ❤️ 🎶 💞",
          "keywords": ["harmony", "nurture", "peace", "love"]
        },
        "3": {
          "emoticons": "😊 🎨 💃 🌟",
          "keywords": ["joy", "creativity", "passion"]
        },
        "4": {
          "emoticons": "🔐 🛠️ 🌟 💡",
          "keywords": ["integrity", "reliability", "enlighten", "inspire"]
        },
        "5": {
          "emoticons": "🌍 ⚡ 🤝 🌱",
          "keywords": ["contribute", "energetic", "betterment", "mankind"]
        },
        "6": {
          "emoticons": "❤️ 📚 🛌 🤲",
          "keywords": ["care", "teach", "comfort", "kindness"]
        },
        "7": {
          "emoticons": "🔍 📚 🧠 ⭐",
          "keywords": ["discover", "truths", "benefit", "analytical"]
        },
        "8": {
          "emoticons": "🌟 💼 🎯 💫",
          "keywords": ["influence", "quality", "inspiration", "enhance"]
        },
        "9": {
          "emoticons": "🕊️ 🚶‍♂️ 🌍 ❤️",
          "keywords": ["lead", "grace", "humanity", "example"]
        },
        "11": {
          "emoticons": "🌟 🎵 💖 ✨",
          "keywords": ["peace", "beauty", "honor", "spiritual"]
        },
        "22": {
          "emoticons": "🌍 🏗️ ❤️ ✨",
          "keywords": ["serving", "Master Builder", "visionary", "humanitarianism"]
        }
      },
      "agenda": {
        "1": {
          "emoticons": "🚀 🛤️ 👑",
          "keywords": ["lead", "innovate", "unique paths"]
        },
        "2": {
          "emoticons": "🕊️ ❤️ 🤝",
          "keywords": ["peace", "harmony", "relationships"]
        },
        "3": {
          "emoticons": "🎭 🌎 😁",
          "keywords": ["entertain", "explore", "joy"]
        },
        "4": {
          "emoticons": "🏛️ 🔍 🔄",
          "keywords": ["stability", "logic", "perseverance"]
        },
        "5": {
          "emoticons": "🌈 🌍 🦅",
          "keywords": ["freedom", "explore", "unrestrained"]
        },
        "6": {
          "emoticons": "❤️ 🌍 🤲",
          "keywords": ["humanitarian", "caring", "Earth"]
        },
        "7": {
          "emoticons": "📚 🔭 🖼️ 🧘",
          "keywords": ["knowledge", "science", "art", "spirituality"]
        },
        "8": {
          "emoticons": "💼 💰 💡",
          "keywords": ["power", "wealth", "enlighten"]
        },
        "9": {
          "emoticons": "🌟 🌍 ❤️",
          "keywords": ["finest", "humanitarian", "devotion"]
        },
        "11": {
          "emoticons": "💖 🌈 🕊️",
          "keywords": ["heal", "counsel", "serve humanity"]
        },
        "22": {
          "emoticons": "🏗️ 🌐 ✨",
          "keywords": ["monumental feats", "betterment", "mankind"]
        }
      }
    };
  
    // Convert the category to lowercase to ensure case-insensitive matching
    const key = category.toLowerCase();
  
    // Return the emoticon or a placeholder if the category is not found
    return emoticons[key][number].emoticons || '❓';
  }


  const personalMeanings = (category, number) => {
    const meanings = {
    "personalYear":
      {
        "1": {
          "meaning": "New Beginnings: A year of fresh starts and opportunities, ideal for initiating projects and embracing independence.",
          "emoticons": "🌱 🚀 ✨",
          "keywords": ["new beginnings", "fresh starts", "independence"]
        },
        "2": {
          "meaning": "Partnership: A period marked by cooperation and relationship-building, perfect for fostering connections and diplomacy.",
          "emoticons": "🤝 💞 ⚖️",
          "keywords": ["partnership", "cooperation", "relationships", "diplomacy"]
        },
        "3": {
          "meaning": "Creativity: A year brimming with creative energy and expression, encouraging you to pursue artistic endeavors and communication.",
          "emoticons": "🎨 🎤 💬",
          "keywords": ["creativity", "expression", "artistic endeavors"]
        },
        "4": {
          "meaning": "Foundation: This year is all about building stability and working hard, focusing on laying down the groundwork for future success.",
          "emoticons": "🏗️ 🛠️ 📈",
          "keywords": ["foundation", "stability", "hard work", "success"]
        },
        "5": {
          "meaning": "Change: A dynamic year of change and freedom, inviting you to explore new horizons and adapt to shifting circumstances.",
          "emoticons": "🌀 🌍 ✈️",
          "keywords": ["change", "freedom", "new horizons"]
        },
        "6": {
          "meaning": "Responsibility: A time to nurture your relationships and take care of others, emphasizing love, family, and community support.",
          "emoticons": "❤️ 👨‍👩‍👧‍👦 🏡",
          "keywords": ["responsibility", "nurture", "family", "community"]
        },
        "7": {
          "meaning": "Reflection: A year for introspection and personal growth, ideal for engaging in spiritual or intellectual pursuits.",
          "emoticons": "🧘‍♂️ 📚 💭",
          "keywords": ["reflection", "introspection", "personal growth"]
        },
        "8": {
          "meaning": "Achievement: A year of potential material and professional gains, focusing on ambition and financial growth.",
          "emoticons": "💼 💰 🏆",
          "keywords": ["achievement", "material gains", "ambition"]
        },
        "9": {
          "meaning": "Completion: A time of endings and reflections, preparing you to close chapters and make way for new beginnings.",
          "emoticons": "🔚 🌅 🔄",
          "keywords": ["completion", "endings", "new beginnings"]
        },
        "11": {
          "meaning": "Spiritual Insight: A powerful year for spiritual awakening and finding your higher purpose, enhancing intuition and inspiration.",
          "emoticons": "🌌 💡 🙏",
          "keywords": ["spiritual insight", "awakening", "higher purpose"]
        },
        "22": {
          "meaning": "Significant Achievements: A year to realize big dreams and projects, with the potential to impact the community or even the world at large.",
          "emoticons": "🌍 🏰 ✨",
          "keywords": ["significant achievements", "big dreams", "world impact"]
        }
      },

      "personalMonth": {
        "1": {
          "meaning": "New Beginnings Month: Embrace leadership and start new ventures. A month for pioneering thoughts and actions.",
          "emoticons": "🌟 🚀 👑",
          "keywords": ["new beginnings", "leadership", "ventures"]
        },
        "2": {
          "meaning": "Connection Month: Focus on partnerships and harmony. Cooperation and understanding should be your priorities this month.",
          "emoticons": "🤝 💞 ⚖️",
          "keywords": ["connection", "partnerships", "harmony"]
        },
        "3": {
          "meaning": "Creativity Month: Let your imagination and expressiveness flow. An ideal month for artistic pursuits and socializing.",
          "emoticons": "🎨 🌈 💬",
          "keywords": ["creativity", "imagination", "artistic"]
        },
        "4": {
          "meaning": "Stability Month: Dedicate effort to organization and building a solid foundation. A month for practical activities and hard work.",
          "emoticons": "🏗️ 📐 🔒",
          "keywords": ["stability", "organization", "foundation"]
        },
        "5": {
          "meaning": "Adventure Month: Be open to change and spontaneity. Explore, travel, or try something new to break the routine.",
          "emoticons": "🌍 ✈️ 🏞️",
          "keywords": ["adventure", "change", "explore"]
        },
        "6": {
          "meaning": "Care Month: Prioritize family and close relationships. Show compassion and take time to support those in need.",
          "emoticons": "❤️ 👨‍👩‍👧‍👦 🩹",
          "keywords": ["care", "family", "compassion"]
        },
        "7": {
          "meaning": "Reflection Month: Engage in introspection and seek knowledge. A perfect month for meditation, study, or solitude.",
          "emoticons": "🧘‍♂️ 📚 💭",
          "keywords": ["reflection", "introspection", "knowledge"]
        },
        "8": {
          "meaning": "Achievement Month: Pursue professional and financial goals with determination. A month marked by potential success and empowerment.",
          "emoticons": "💼 💰 🏆",
          "keywords": ["achievement", "professional", "empowerment"]
        },
        "9": {
          "meaning": "Release Month: Let go of the past and clear the way for new beginnings. Forgiveness and closure are key themes.",
          "emoticons": "🍃 🔄 🕊️",
          "keywords": ["release", "new beginnings", "forgiveness"]
        },
        "11": {
          "meaning": "Inspiration Month: Your intuition and spiritual awareness are heightened. Seek deeper connections and inspire others.",
          "emoticons": "💫 🙏 🌌",
          "keywords": ["inspiration", "intuition", "spiritual awareness"]
        },
        "22": {
          "meaning": "Visionary Month: Focus on larger goals and projects that can have a lasting impact. A month to build legacies and dream big.",
          "emoticons": "🏛️ 🌍 ✨",
          "keywords": ["visionary", "lasting impact", "legacies"]
        }
      },
      "personalDay": {
        "1": {
          "meaning": "Innovation Day: Embrace leadership and start new ventures. It's a day for pioneering thoughts and actions.",
          "emoticons": "💡 🚀 👑",
          "keywords": ["innovation", "leadership", "new ventures"]
        },
        "2": {
          "meaning": "Connection Day: Focus on partnerships and harmony. Cooperation and understanding should be your priorities.",
          "emoticons": "🤝 💞 ⚖️",
          "keywords": ["connection", "partnerships", "harmony"]
        },
        "3": {
          "meaning": "Creativity Day: Let your imagination and expressiveness flow. An ideal day for artistic pursuits and socializing.",
          "emoticons": "🎨 🌈 💬",
          "keywords": ["creativity", "imagination", "artistic pursuits"]
        },
        "4": {
          "meaning": "Stability Day: Dedicate effort to organization and building a solid foundation. A day for practical activities and hard work.",
          "emoticons": "🏗️ 📐 🔒",
          "keywords": ["stability", "organization", "foundation"]
        },
        "5": {
          "meaning": "Adventure Day: Be open to change and spontaneity. Explore, travel, or try something new to break the routine.",
          "emoticons": "🌍 ✈️ 🏞️",
          "keywords": ["adventure", "change", "explore"]
        },
        "6": {
          "meaning": "Care Day: Prioritize family and close relationships. Show compassion and take time to support those in need.",
          "emoticons": "❤️ 👨‍👩‍👧‍👦 🩹",
          "keywords": ["care", "family", "compassion"]
        },
        "7": {
          "meaning": "Reflection Day: Engage in introspection and seek knowledge. It's a perfect time for meditation, study, or solitude.",
          "emoticons": "🧘‍♂️ 📚 💭",
          "keywords": ["reflection", "introspection", "knowledge"]
        },
        "8": {
          "meaning": "Achievement Day: Pursue professional and financial goals with determination. A day marked by potential success and empowerment.",
          "emoticons": "💼 💰 🏆",
          "keywords": ["achievement", "professional goals", "empowerment"]
        },
        "9": {
          "meaning": "Release Day: Let go of the past and clear the way for new beginnings. Forgiveness and closure are key themes.",
          "emoticons": "🍃 🔄 🕊️",
          "keywords": ["release", "new beginnings", "forgiveness"]
        },
        "11": {
          "meaning": "Inspiration Day: Your intuition and spiritual awareness are heightened. Seek deeper connections and inspire others.",
          "emoticons": "💫 🙏 🌌",
          "keywords": ["inspiration", "intuition", "spiritual awareness"]
        },
        "22": {
          "meaning": "Visionary Day: Focus on larger goals and projects that can have a lasting impact. It's a day to build legacies and dream big.",
          "emoticons": "🏛️ 🌍 ✨",
          "keywords": ["visionary", "lasting impact", "legacies"]
        }
      }
    }

    return meanings[category][number] || '❓';
  }

  const getPersonal = (category) => {
    const categories = {
      "personalYear": personalYear,
      "personalMonth": personalMonth,
      "personalDay": personalDay
    }
    return categories[category]
  }

  function handleNewChat() {
    if (user) {
      // setConversationId(user.uid + Date.now());
    }
    
    clearInput();
    clearChat();
    setIsRateLimited(false);
    setResponseFailed(false);
    setBestProducts([]);
    setTopProducts([]);
    setProducts([]);
  }

  // Redirect user to google login
  function handleLogin() {
    setIsLoggingIn(true);
    signInWithPopup(auth, provider);
    getRedirectResult(auth)
      .then(() => {
        console.log("Signed In");
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  // Log user out, clear old messages and input
  function handleLogout() {
    signOut(auth)
      .then(() => {
        console.log("Logged out");
        clearInput();
        clearChat();
        setIsLoading(false);
        setIsRateLimited(false);
        window.location = "/"
        localStorage.removeItem('login')
        localStorage.removeItem('country')
        localStorage.removeItem('token')
        localStorage.removeItem('user_id')
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Check if a user has had a free trial and create one in database if they haven't
  const handleTrialPeriod = (user) => {
    const userRef = ref(db, `trials/${user.uid}`);

    get(userRef).then((snapshot) => {
      if (snapshot.val() === null) {
        // create a new user document with the createdAt timestamp
        set(userRef, {
          createdAt: Date.now(),
        })
          .then(() => {
            console.log("Trial Started");
            checkTrialExpired(user);
          })
          .catch((error) => {
            console.log("The write failed...", error);
          });
      }
    });
  };

  // Checks if user has free trial time
  const checkTrialExpired = async (user) => {
    if (!user) return;

    const userRef = ref(db, `trials/${user.uid}`);

    get(userRef).then((snapshot) => {
      if (snapshot.val() === null) return;

      const trialData = snapshot.val();
      const createdAt = trialData.createdAt;
      const now = Date.now();

      const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
      const trialDuration = now - createdAt;

      // Return true if the trial has not yet expired (is less than one day old)
      // if (trialDuration < oneDayInMilliseconds) {
      if (true) {
        setHasTrial(true);
      }
    });
  };

  
  useEffect(() => {  }, []);


  const [input, setInput] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  // Default temperature is 0.5 (must match temperature sliders default value/100)
  const [temperature, setTemperature] = useState(0.5);

  // Set temperature to 0 when user swaps to codex model and 0.5 when swapping back
  useEffect(() => {
    setTemperature(selectedModel === "code-davinci-002" ? 0 : 0.5);
  }, [selectedModel]);

  function clearChat() {
    setChatLog([]);
  }

  function clearInput() {
    setInput("");
  }

  const getUserProfile = async (uid) => {
    if (!localStorage.getItem('token')) {
      return
    }

    try {

      console.log("entro")
      const resp = await getUser(uid);
      const respJson = await resp.json()
      setUserProfile(respJson.data);
      getPersons(uid)
      getUserCategories(uid)
      const [ryear, rmonth, rday] = respJson.data.birthday.split('-');
      const year = getYearNumber(rday, rmonth)
      const month = getMonthNumber(year)
      const day = getPersonalDay(month)
      setPersonalYear(year); 
      setPersonalMonth(month);
      setPersonalDay(day);
      setIsFetching(false);
    } catch(e) {
      setIsFetching(false);
      console.log(e);
    }    
  }

  const getUserCategories = async (uid) => {
    if (!localStorage.getItem('token')) {
      return
    }

    try {
      const resp = await getCategories(uid);
      const respJson = await resp.json()
      setUserCategories(respJson);
      setIsLoadingPage(false)
    } catch(e) {
      setIsFetching(false);
      setIsLoadingPage(false)
      console.log(e);
    }    
  }

  const getCategoryFromServer = async (uid, category) => {
    if (!localStorage.getItem('token')) {
      return
    }
    try {
      const resp = await getCategory(uid, category);
      const respJson = await resp.json()
      setUserCategories(respJson);
      setButtonDisabled(prevState => ({ ...prevState, [category]: false }));
    } catch(e) {
      setButtonDisabled(prevState => ({ ...prevState, [category]: false }));
      setIsFetching(false);
      console.log(e);
    }    
  }

  const getDataCategory = (category, setButtonDisabled, buttonDisabled) => {
    const found = userCategories.data.entries.filter((e) => {
      return e.category == category.name
    });

    if (found.length > 0) {
      return <Markdown remarkPlugins={[remarkGfm]}>{ found[0].description }</Markdown> 
    } else {
      return <Button size="small" 
        disabled={buttonDisabled[category.name] || false}
        onClick={() => {
          if (category.type === 'Get Free 🆓' || (userProfile && userProfile.status === 'paid')) {
            setButtonDisabled(prevState => ({ ...prevState, [category.name]: true })); 
            getCategoryFromServer(user.uid, category.name)
          } else {
            // window.open("https://buy.stripe.com/bIY7vE8vTdAx8A89AC")
            window.open("https://buy.stripe.com/3cs2bkdQd7c9dUsfZ1")
          }
        }} 
        style={{
          marginTop: 30,
          color: 'white', 
          backgroundColor: buttonDisabled[category.name] ? 'rgb(102 102 102)' : category.color,
          border: 'none',
          padding: '8px 15px', 
          borderRadius: '25px', 
          fontSize: '12px',  
          fontWeight: 'bold',
          cursor: 'pointer', 
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
          outline: 'none', 
          width: '100%',  
          display: 'flex',
          justifyContent: 'center', 
          alignItems: 'center',  
          textTransform: 'uppercase'
        }} >  { buttonDisabled[category.name] ? 'Loading ... ⏳' : (userProfile && userProfile.status === 'paid') ?  'Get Now 🔓' : category.type }</Button>
    } 
  }

  // Categories
  const categoriesAI =  [
    {
      'name': 'numerology_interpretation',
      'title': '📜 Numerology Interpretation',
      'type': 'Get Free 🆓',
      'color': 'rgb(64 41 241)'
    },
    {
      'name': 'phrase',
      'title': '🌟 Your Essence',
      'type': 'Get Free 🆓',
      'color': 'rgb(64 41 241)'
    },
    {
      'name': 'passions',
      'title': '🤩 Passions & Interets',
      'type': 'Get Free 🆓',
      'color': 'rgb(64 41 241)'
    },
    {
      'name': 'heal',
      'title': '🌿 How to heal?',
      'type': 'Get Free 🆓',
      'color': 'rgb(64 41 241)'
    },
    {
      'name': 'who_i_am',
      'title': '🔍 Who really am I?',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    },
    {
      'name': 'life_purpose',
      'title': '🚀 Life Purpose',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    },
    {
      'name': 'emotional_stability',
      'title': '😌 Emotional Stability',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    },
    {
      'name': 'potential',
      'title': '⚡ Potential Areas',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    },
    {
      'name': 'superpowers',
      'title': '🤩 Superpowers',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    },
    {
      'name': 'dificult_moments',
      'title': '😣 Read in Difficult Moments',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    },
    {
      'name': 'wealth',
      'title': '💰 Wealth',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    },
    {
      'name': 'money_blockers',
      'title': '💸🚫 Money Blockers',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    },
    {
      'name': 'love',
      'title': '❤️ Love',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    },
    {
      'name': 'professional',
      'title': '💼 Professional and Careers',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    },
    {
      'name': 'projects',
      'title': '📋 Projects',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    },
    {
      'name': 'focus_in',
      'title': '🧐 You need to focus on this.',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    },
    {
      'name': 'not_waste_time',
      'title': '🚫 Avoid Focusing on This',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    }, 
    {
      'name': 'how_to_take_decisions',
      'title': '🤔 Take Decisions',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    },  
    {
      'name': 'how_to_solve_hard_problems',
      'title': '💡 Solve hard problems',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    }, 
    {
      'name': 'manifesting',
      'title': '🌈 How to Manifesting Material Things?',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    },
    {
      'name': 'books_recomendations',
      'title': '📖 Books to read',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    },  
    {
      'name': 'yt_recomendations',
      'title': '🎬 Youtube Channels',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    },
    {
      'name': 'music_recomendations',
      'title': '🎵 Music',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    },
    {
      'name': 'ikigai',
      'title': '💖 Your IKIGAI',
      'type': 'Unlock 🔒',
      'color': 'rgb(8 4 35)'
    }
  ];

  const getPersons = async (uid) => {
    try {
      const request = await listPersons(uid);
      const response = await request.json()
      setMessagesHistory(response.data.entries);   
    } catch(e) {
      setMessagesHistory([]);
    }
  }

  const getCurrentYear = () => {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }
  
  async function handleSubmit(e) {
    e.preventDefault();

    if (user) {
      setConversationId(user.uid + Date.now());
    }

    clearChat();
    setIsRateLimited(false);
    setResponseFailed(false);
    setBestProducts([]);
    setTopProducts([]);
    setProducts([]);

    // Create reference to user's chat log in the database
    const chatRef = ref(db, `messages/${user.uid}/${conversationId}`);
    // Create reference to user's token usage in the database
    const tokenRef = ref(db, `token-usage/${user.uid}`);
    // Add user's input to the chat log
    let chatLogNew = [...chatLog, { user: "me", message: `${input}` }];

    // Check if the user is logged in
    if (user) {
      // Push the user's input to the database
      push(chatRef, {
        user: "me",
        message: input,
        timestamp: Date.now(),
      })
        .then(() => {})
        // Log the error if the write failed
        .catch((error) => {
          console.log("The write failed...", error);
        });
    }

    // clearInput(); // Clear the input field
    setChatLog(chatLogNew); // Set the new chat log with user's input
    setIsLoading(true);

    // Give the AI the last 5 messages for context as well as the recent input
    const lastSix = chatLogNew.slice(Math.max(chatLogNew.length - 6, 0));
    const messages = lastSix.map((message) => message.message).join("\n");
    const apiURL = "https://zjeolwdjow-rimvrjej3q-uc.a.run.app";
    const minB = '0';
    const maxB = '1000000';

    // Fetch response from the backend
   const response = await fetch(
    `${apiURL}/api/v1/search?query=${input}&country=${localStorage.getItem('country')}&minB=${parseFloat(minB).toFixed(2)}&maxB=${parseFloat(maxB).toFixed(2)}`
    ).catch((error) => {
      console.log(error)
      // Show response failed error
      setResponseFailed(true);
      setIsLoading(false);
      throw new Error("API response was not okay");
    });

    setIsLoading(false);
    // If user hit rate limit display error
    if (response.status === 429) {
      setIsRateLimited(true);
      setIsLoading(false);
      throw new Error("Rate limit reached");
    }

    // Response was successful
    setIsRateLimited(false);
    setResponseFailed(false);

    // Get the response data in JSON format
    const data = await response.json();
    setBestProducts(data.data.best);
    setTopProducts(data.data.top_products_all);
    setProducts(data.data.products)
    
    const data2 = {
      message: messages,
      token_usage: 0
    }
    // Push the AI response to the database
    push(chatRef, {
      user: "gpt",
      message: messages,
      best: data.data.best,
      top: data.data.top_products_all,
      products: data.data.products,
      timestamp: Date.now(),
    })
      .then(() => {})
      .catch((error) => {
        console.log("The write failed...", error);
      });

    // Push the token usage to the database
    update(tokenRef, {
      total_tokens_used: increment(data2.token_usage),
    })
      .then(() => {})
      .catch((error) => {
        console.log("The write failed...", error);
      });

    // Set the chat log with the AI response
    setChatLog([...chatLogNew, { user: "gpt", message: `${data2.message}` }]);
    setIsLoading(false);
  }

  if (!localStorage.getItem('login')) {
    window.location = "/"
  }

  // Get users subscription data
  const loadSubscription = async () => {
    // Get the current user
    const sub = auth.currentUser;
    // Get the reference to the user's subscriptions
    const ref = await getDocs(
      collection(firestoreDB, `customers/${sub.uid}/subscriptions`)
    );

    // Set subscription details in state
    ref.forEach(async (doc) => {
      setSubscription({
        role: doc.data().role,
        current_period_end: doc.data().current_period_end,
        current_period_start: doc.data().current_period_start,
        ended_at: doc.data().ended_at,
        status: doc.data().status,
      });
    });
  };

  const setConversation = async (userId, persona) => {
    if (!localStorage.getItem('token')) {
      return
    }

    var data = {}
    if (persona.accessToken) {
      try {
        const request = await getUser(userId)
        const user = await request.json()
        data = user.data;
        getUserCategories(userId)
      } catch (e) {
        console.log(e);
      }
    } else {
      data = persona
      setUserCategories([]);
    }
    setUserProfile(data)
    const [ryear, rmonth, rday] = data.birthday.split('-');
    const year = getYearNumber(rday, rmonth)
    const month = getMonthNumber(year)
    const day = getPersonalDay(month)
    setPersonalYear(year); 
    setPersonalMonth(month);
    setPersonalDay(day);
  }; 


  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawerR = () => {
    setShowDrawer(!showDrawer);
    console.log(!showDrawer)
  };

  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };


  const [expanded, setExpanded] = useState(Array(categoriesAI.length).fill(false)); // Array to track expanded state of each card

  const handleExpandClick = (categoryName) => {
    setExpanded(prevState => ({
      ...prevState,
      [categoryName]: !prevState[categoryName]
    }));
  };


  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  
  return (
    <div className="App">
      {/* Side menu (not visible on mobile) */}    
      <aside className={`side-menu ${showDrawer ? "show" : "hidden"}`}>
        <span>963.ai</span>

        <><button className="new-chat-button" onClick={() => { window.location='/app'}}><span></span>Home</button></>
        <><button className="new-chat-button" onClick={() => { window.location='/chat'}}><span></span>+ AI Chat</button></>
        {/* <><button className="new-chat-button"><span></span>Today</button></>
        <><button className="new-chat-button"><span></span>Analize / Discover</button></>
        <><button className="new-chat-button"><span></span>Find Person</button></> */}
        <NewPersonButton 
          handleNewChat={handleNewChat} 
          isLoading={isLoading} 
          app={app} 
          db={db} 
          user={user}
          setConversation={setConversation}
          setMessagesHistory={setMessagesHistory}
          getPersons={getPersons}
          messagesHistory={messagesHistory}
        />

        {/* Display message history if user is logged in */}
        {userProfile && userProfile.birthday && (
          <Persons
            userId={user.uid}
            conversationId={conversationId}
            setConversation={setConversation}
            db={db}
            setMessagesHistory={setMessagesHistory}
            messagesHistory={messagesHistory}
          />
        )}
        <div className="white-buttons">
          <ClearConversations user={user} db={db} />
          <BugReportModal user={user} db={db} />
          <EditProfileButton user={user} setIsOpenEditProfile={setIsOpenEditProfile} />
        </div>

        {/* Displays users free trial status */}
        {/* {<FreeTrial />} */}

        {/* Takes user to the store, only displayed if user is logged in */}
        {/* <StoreButton user={user} /> */}

        {/* Login/logout buttons */}
        {!authLoading && (
          <GoogleAuthButtons
            user={user}
            handleLogin={handleLogin}
            handleLogout={handleLogout}
          />
        )}
      </aside>

      {/* Chat box */}
      <section className="chat-box">
      <AppBar position="static" style={{backgroundColor: "black", position: 'fixed'}}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawerR}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          </Typography>
          <Button color="inherit"onClick={handleLogout} >logout</Button>
        </Toolbar>
      </AppBar>
      
        {/* If the user is subscribed or has trial display the chat input form */}
        {/* New chat button for mobile only */}
        { userProfile && !isFetching && <Profile user={user} userProfile={userProfile} isOpenEditProfile={isOpenEditProfile} setIsOpenEditProfile={setIsOpenEditProfile}/>}
        {/* Only display hero if theres no chats and if user isn't rate limited */}
        {/* {chatLog.length === 0 && !isRateLimited && <Hero />} */}
        {/* Store button for mobile only */}
        {/* <div className="mobile-store-button">
          {chatLog.length === 0 && <StoreButton user={user} />}
        </div> */}
      
      {!userProfile && <CircularProgress style={{ top: 50, color: "#b3befe" }} />}
      
      {userProfile && userProfile.birthday &&<div className="chat-log">
        <div style={{paddingBottom: 15,fontSize:25, fontWeight: 700, color: 'black'}}>
          {userProfile.fullname ? userProfile.fullname : 'Complete your profile'}  {userProfile.relation_type ? `- ${userProfile.relation_type}`: '' }  
          {!userProfile.relation_type && <button key={0} className="menu-button" onClick={() => { window.location = '/chat'}}>AI Chat ✨</button>} 
        </div>
        <Grid container spacing={{ xs: 2, sm: 2, md: 2}} columns={{ xs: 4, sm: 6, md: 6, lg: 8 }}>
            {['lifepath',  'destiny', 'purpose', 'agenda', 'soul', 'character', 'personality', 'attitude'].map((e, i) => {

              console.log(`${userProfile.numerology[e].title}: ${userProfile.numerology[e].number}`);
              
              return <Grid item xs={2} sm={2} md={2} lg={1} key={i}> 
                <Card sx={{ maxWidth: 200 }} sm={{ maxWidth: 240 }} md={{ maxWidth: 240 }} lg={{ maxWidth: 240 }}>
                  <CardContent style={{backgroundColor: "#fbfbfb", minHeight: 210}}> 
                    <Typography sx={{ fontSize: 15, color: "black", fontWeight: 700 }} color="text.primary" gutterBottom>
                    {getEmoticonByCategory(e)} {userProfile.numerology[e].title}:  {userProfile.numerology[e].number}
                    </Typography>
                    <Typography sx={{ fontSize: 12, color: "black" }} color="#ffd300">
                      {userProfile.numerology[e].meaning}
                      </Typography>
                      <Typography sx={{ marginTop: 2, fontSize: 12, fontStyle: 'italic',color: "#6b6b6b" }} color="#ffd300">                      
                      {userProfile.numerology[e].description}
                      </Typography>
                      
                  </CardContent>
                  <CardContent>
                  <Typography style={{fontSize: 25}}>
                        {getEmoticonsMeaning(e, userProfile.numerology[e].number)}
                      </Typography>
                  </CardContent>
                  
                  <CardActions style={{backgroundColor: "#353740"}}> 
                    <Button size="small" onClick={()=> {navigator.clipboard.writeText("")}} style={{color: 'white'}}></Button>
                  </CardActions>
                </Card>
              </Grid>
            })}
        </Grid>
      </div>
      }

      {personalYear && personalMonth && personalDay && <div className="chat-log">
        <div style={{paddingBottom: 15,fontSize:25, fontWeight: 700, color: 'black'}}>Personal numbers for: {getCurrentYear()}</div>
          <Grid container spacing={{ xs: 2, sm: 2, md: 2}} columns={{ xs: 4, sm: 6, md: 6, lg: 8 }}>
          {['personalYear',  'personalMonth', 'personalDay'].map((e, i) => {
              return <Grid item xs={2} sm={2} md={2} lg={1} key={i}> 
              <Card sx={{ maxWidth: 200 }} sm={{ maxWidth: 240 }} md={{ maxWidth: 240 }} lg={{ maxWidth: 240 }}>
                <CardContent style={{backgroundColor: "white"}}> 
                  <Typography sx={{ fontSize: 15, color: "black", fontWeight: 700 }} color="text.primary" gutterBottom>
                  For this {e.replace("personal", "")}: {getPersonal(e)}
                  </Typography>
                </CardContent>

                <CardContent  style={{backgroundColor: "white", minHeight: 150}}>
                    <Typography sx={{ fontSize: 12, color: "black" }} color="#ffd300">
                    {personalMeanings(e, getPersonal(e)).meaning}
                    </Typography>
                    <Typography style={{fontSize: 25, paddingTop: 15}}>
                    {personalMeanings(e, getPersonal(e)).emoticons}
                    </Typography>
                  </CardContent>
                <CardActions style={{backgroundColor: "#353740"}}> 
                  <Button size="small" onClick={()=> {navigator.clipboard.writeText("")}} style={{color: 'white'}}></Button>
                </CardActions>
              </Card>
            </Grid>
          })}
          </Grid>
        </div>}

        {userProfile && userProfile.birthday && userCategories && userCategories.data && (<div className="chat-log">
        <div style={{paddingBottom: 10,fontSize:25, fontWeight: 700, color: 'Black'}}>Know yourself ✨</div>
            {(
            <div>
              <button className="floating-button" onClick={toggleMenu}>
                ☰
              </button>
              <div className={menuVisible ? "menu-overlay active" : "menu-overlay"}>
                <div className="menu-container">
                  <button key={0} className="menu-button" onClick={() => { window.location = '/chat'}}>AI Chat ✨</button>
                  {categoriesAI.map((category, index) => (
                    <button key={index} className="menu-button" onClick={() => { 
                      window.location = '#' + category.name;
                      handleExpandClick(category.name);
                      toggleMenu(); 
                     }}>
                      {category.title}
                    </button>
                  ))}
                </div>
              </div> 
              <div >
                <div className="menu-container-2">
                <button key={0} className="menu-button" onClick={() => { window.location = '/chat'}}>AI Chat ✨</button>
                  {categoriesAI.map((category, index) => (
                    <button key={index} className="menu-button" onClick={() => { 
                      handleExpandClick(category.name);
                      window.location = '#' + category.name;
                     }}>
                      {category.title}
                    </button>
                  ))}
                </div>
              </div> 
            </div>
          )}

          <Grid container spacing={{ xs: 2, sm: 2, md: 2}} columns={{ xs: 1, sm: 2, md: 4, lg: 4 }} style={{marginTop: 15, marginBottom:100}}>
            {categoriesAI.map((e, i) => (
            <Grid item xs={12} sm={6} md={3} lg={2} key={i}>
              <Card sx={{ maxWidth: '98%' }} id={e.name} style={{padding: 1}} sx={{
                maxWidth: '98%',
                borderRadius: '10px', // Rounded corners
                bgcolor: 'white',
                color: 'white',
                mb: 2, // margin bottom
                '&:hover': {
                  bgcolor: 'white' // Dark grey on hover
                }
              }}>
                <CardContent style={{ backgroundColor: '#f7f7f7' }}>
                  <CardActions disableSpacing style={{ backgroundColor: '#f7f7f7', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 1 }} >
                    <Typography variant="subtitle1" component="div" style={{ color: 'black', fontWeight:800, flexGrow: 1, paddingLeft: '1px' }}>
                      {userCategories.data && e.title}
                    </Typography>
                    <IconButton
                      onClick={() => handleExpandClick(e.name)}
                      aria-expanded={expanded[e.name] || false}
                      aria-label="show more"
                      style={{ color: 'black' }}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </CardActions> 
                </CardContent>
                <CardContent style={{ display: expanded[e.name] ? 'block' : 'none', backgroundColor: "#f7f7f7", minHeight: 0 }}>
                  <Typography sx={{ fontSize: 12, color: "black" }}>
                    {userCategories.data && getDataCategory(e, setButtonDisabled, buttonDisabled)} 
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            ))}
          </Grid>
        </div>)}
          
        {/* Error messages */}
        {isRateLimited && <RateLimitError />}
        {responseFailed && <ResponseFailedError />}

        {/* 
          Login button for mobile only 
          Right now its set up to only display the login button need to rework
          the ui for mobile 
        */}
        {/* {!user && !authLoading && (
          <div className="mobile-login-button">
            <GoogleAuthButtons
              user={user}
              handleLogin={handleLogin}
              handleLogout={handleLogout}
            />
          </div>
        )} */}
        {/* If the user is not signed in display sign up heading */}
        {/* {!user && !authLoading && (
          <SignUpHeading handleLogin={handleLogin} isLoggingIn={isLoggingIn} />
        )} */}
        {/* If user is subscribed or has trial display temperature slider */}
        {/* {hasTrial ||
        (subscription?.role === "premium" &&
          !subscription?.ended_at &&
          !isRateLimited &&
          selectedModel === "text-davinci-003" &&
          subscription?.status !== "incomplete") ? (
          <TemperatureSlider setTemperature={setTemperature} user={user} />
        ) : null}  */}
        {/* If user is in code mode show coding mode guide */}

        {/* If the user isn't subscribed display the visit store heading */}
        {/* {subscription?.role !== "premium" && user && !hasTrial && (
          <NotSubscribedHeading />
        )} */}
        {/* Loading spinner when user is logging in */}
        
        {/* {hasTrial ||
        (subscription?.role === "premium" &&
            !subscription?.ended_at &&
            !isRateLimited &&
            subscription?.status !== "incomplete") ? (
            <ChatInputForm
              input={input}
              setInput={setInput}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              user={user}
              handleLogin={handleLogin}
              selectedModel={selectedModel}
            />
          ) : null} */}
        <Disclaimer />
      </section>
    </div>
  );
}

export default MainPage;
