import localStorage from 'localStorage'

const apiURL = "https://zjeolwdjow-rimvrjej3q-uc.a.run.app";
// const apiURL = "http://localhost:4000";

export const authUserService = (body) => {
   return fetchWithAuth(`${apiURL}/api/v1/auth/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  }) 
}

export const updateUser = (uid, body) => {
    return fetchWithAuth(`${apiURL}/api/v1/users/${uid}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      })
}


export const getUser = (uid) => {
    return fetchWithAuth(`${apiURL}/api/v1/users/${uid}`)
}

export const createPerson = (body) => {
    return fetchWithAuth(`${apiURL}/api/v1/persons`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      }) 
}

export const updatePersons = (uid, body) => {
    return fetchWithAuth(`${apiURL}/api/v1/persons/${uid}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      })
}


export const deletePersons = (uid) => {
    return fetchWithAuth(`${apiURL}/api/v1/persons/${uid}`, {
        method: 'Delete',
        headers: {
          'Content-Type': 'application/json',
        }
      })
}

export const listChats = (uid) => {
  return fetchWithAuth(`${apiURL}/api/v1/chat/byUser/${uid}`)
}

export const listMessages = (uid) => {
  return fetchWithAuth(`${apiURL}/api/v1/messages/byChat/${uid}`)
}

export const sendMessage = (body) => {
  return fetchWithAuth(`${apiURL}/api/v1/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    }) 
}

export const listPersons = (uid) => {
    return fetchWithAuth(`${apiURL}/api/v1/persons/byUser/${uid}`)
}

export const getCategories = (uid) => {
  return fetchWithAuth(`${apiURL}/api/v1/users/categories/${uid}`)
}

export const getCategory = (uid, category) => {
  return fetchWithAuth(`${apiURL}/api/v1/users/categories/${uid}/${category}`)
}

async function fetchWithAuth(url, options = {}) {
    const token = localStorage.getItem('token');
    options.headers = options.headers || {};
    if (token) {
      options.headers['Authorization'] = `Bearer ${token}`;
    }
    return fetch(url, options);
}