import { useState, useEffect } from "react";
import NewChatButton from "../Components/NewChatButton";
import GoogleAuthButtons from "../Components/GoogleAuthButtons";
import MessageHistory from "../Components/MessageHistory";
import BugReportModal from "../Components/BugReportModal";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  getRedirectResult,
  signOut,
  onAuthStateChanged
} from "firebase/auth";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { ref, push, get, set, update, increment } from "firebase/database";
import "./ChatPage.css";
import Disclaimer from "../Components/Disclaimer";
import ClearConversations from "../Components/ClearConversations";
import RateLimitError from "../Components/RateLimitError";
import ResponseFailedError from "../Components/ResponseFailedError";
import localStorage from 'localStorage'
import { makeStyles } from '@mui/styles';
import { Typography, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material';
import React from 'react';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Profile from "../Components/Profile";
import { getUser, listChats, listMessages } from "../Services/api";
import EditProfileButton from "../Components/EditProfileButton";
import ChatAI from "../Components/ChatAI";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  listItemAvatar: {
    minWidth: 'auto',
  },
  productTitle: {
    fontWeight: 'bold',
  },
  productPrice: {
    fontWeight: 'bold',
  },
}));


function ChatPage({ app, db }) {
  const provider = new GoogleAuthProvider(app);
  const auth = getAuth(app);
  const firestoreDB = getFirestore(app);

  const [user, setUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [isRateLimited, setIsRateLimited] = useState(false);
  const [responseFailed, setResponseFailed] = useState(false);
  const [selectedModel, setSelectedModel] = useState("text-davinci-003");
  const [isFetching, setIsFetching] = useState(false);
  const [messagesHistory, setMessagesHistory] = useState([]);
  const [isOpenEditProfile, setIsOpenEditProfile] = useState(false);
  const [conversationUID, setConversationUID] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user); 

      if (!localStorage.getItem('token') && !isFetching) {
        setIsFetching(true)    
      }
      getUserProfile(user.uid);
    });  

  }, []);

  function handleLogin() {
    signInWithPopup(auth, provider);
    getRedirectResult(auth)
      .then(() => {
        console.log("Signed In");
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  function handleLogout() {
    signOut(auth)
      .then(() => {
        console.log("Logged out");
        clearInput();
        clearChat();
        setIsLoading(false);
        setIsRateLimited(false);
        window.location = "/"
        localStorage.removeItem('login')
        localStorage.removeItem('country')
        localStorage.removeItem('token')
        localStorage.removeItem('user_id')
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  const [input, setInput] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  // Default temperature is 0.5 (must match temperature sliders default value/100)
  const [temperature, setTemperature] = useState(0.5);

  // Set temperature to 0 when user swaps to codex model and 0.5 when swapping back
  useEffect(() => {
    setTemperature(selectedModel === "code-davinci-002" ? 0 : 0.5);
  }, [selectedModel]);

  function clearChat() {
    setChatLog([]);
  }

  function clearInput() {
    setInput("");
  }

  const getUserProfile = async (uid) => {
    if (!localStorage.getItem('token')) {
      return
    }
    try {
      const resp = await getUser(uid);
      const respJson = await resp.json()
      setUserProfile(respJson.data);
      getChats(uid)
    } catch(e) {
      setIsFetching(false);
      console.log(e);
    }    
  }

  const getChats = async (uid) => {
    try {
      setIsFetching(false)
      const request = await listChats(uid);
      const response = await request.json()
      setMessagesHistory(response.data.entries)
      setIsFetching(true)
    } catch(e) {
      setIsFetching(true)
      setMessagesHistory([]);
    }
  }
  
  if (!localStorage.getItem('login')) {
    window.location = "/"
  }

  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawerR = () => {
    setShowDrawer(!showDrawer);
    console.log(!showDrawer)
  };

  
  return (
    <div className="App">
      {/* Side menu (not visible on mobile) */}    
      <aside className={`side-menu ${showDrawer ? "show" : "hidden"}`}>
        <span>963.ai</span>
        <><button className="new-chat-button" onClick={() => { window.location='/app'}}><span></span>Home </button></>
        <><button className="new-chat-button" onClick={() => { window.location='/chat'}}><span></span>+ AI Chat</button></>

        {userProfile && userProfile.birthday && (
          <MessageHistory
            userId={user.uid}
            db={db}
            setMessagesHistory={setMessagesHistory}
            messagesHistory={messagesHistory}
          />
        )}

        <div className="white-buttons">
          {/* <ClearConversations user={user} db={db} /> */}
          <BugReportModal user={user} db={db} />
          <EditProfileButton user={user} setIsOpenEditProfile={setIsOpenEditProfile} />
        </div>

        {!authLoading && (
          <GoogleAuthButtons
            user={user}
            handleLogin={handleLogin}
            handleLogout={handleLogout}
          />
        )}
      </aside>

      {/* Chat box */}
      <section className="chat-box">
      <AppBar position="static" style={{backgroundColor: "black", position: 'fixed'}}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawerR}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          </Typography>
          <Button color="inherit"onClick={handleLogout} >logout</Button>
        </Toolbar>
      </AppBar>
      
      {userProfile && !isFetching && <Profile user={user} userProfile={userProfile} isOpenEditProfile={isOpenEditProfile} setIsOpenEditProfile={setIsOpenEditProfile}/>}
        
      {<ChatAI 
        getChats={getChats}
        userProfile={userProfile}
        db={db}
        setMessagesHistory={setMessagesHistory}
        messagesHistory={messagesHistory}
        isFetching={isFetching}
      />}
    
      {isRateLimited && <RateLimitError />}
      {responseFailed && <ResponseFailedError />}

        <Disclaimer />
      </section>
    </div>
  );
}

export default ChatPage;
