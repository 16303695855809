import React from "react";
import "./GoogleAuthButtons.css";
import GoogleSvg from "./GoogleSvg";

function GoogleAuthButtons({ user, handleLogin, handleLogout }) {
  return (
    <div className="google-button-container">
      {/* If the user is logged in, return a logout button */}
      {user ? (
        <button onClick={handleLogout} style={{color: "white", fontSize: 15}}>
          Logout
        </button>
      ) : (
        // Else user is logged out, return a login button
        <button onClick={handleLogin} style={{color: "white", fontSize: 15}}>
          Login
        </button>
      )}
    </div>
  );
}

export default GoogleAuthButtons;
