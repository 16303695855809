import React, { useState, useEffect } from "react";
import { ref, get, onValue } from "firebase/database";
import { motion } from "framer-motion";
import "./MessageHistory.css";
import { listPersons } from "../Services/api";
import { useNavigate } from 'react-router-dom';


function MessageHistory({ userId, db, setConversation, setMessagesHistory, messagesHistory}) {
  const navigate = useNavigate();
  return (
    <div className="message-history-container">
      {messagesHistory.map((message, index) => (
        <button className="message-button" key={index} onClick={() => navigate(`/chat/${message.uid}`)}>
          <motion.div
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.1 }}
            className="message-container"
          >
            <p className="truncate-text">
              { message.title}
            </p>
          </motion.div>
        </button>
      ))}
    </div>
  );
}

export default MessageHistory;
