import React, { useState, useEffect } from "react";
import { ref, get, onValue } from "firebase/database";
import { motion } from "framer-motion";
import "./Persons.css";
import { listPersons } from "../Services/api";

function Persons({ userId, db, setConversation, setMessagesHistory, messagesHistory}) {
  return (
    <div className="message-history-container">
      {messagesHistory.map((message, index) => (
        <button className="message-button" key={index} onClick={() => setConversation(userId, message)}>
          <motion.div
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.1 }}
            className="message-container"
          >
          
            <p className="truncate-text">
              { message.fullname.charAt(0).toUpperCase() +
                  message.fullname.slice(1)}
            </p>
          </motion.div>
        </button>
      ))}
    </div>
  );
}

export default Persons;
