import { ref, remove } from "firebase/database";
import React, { useState } from "react";
import "./ClearConversations.css";
import { deletePersons } from "../Services/api";

function ClearConversations({ user, db }) {
  const [showConfirm, setShowConfirm] = useState(false);

  const handleClearConversation = () => {
    setShowConfirm(true);
  };

  const handleConfirm = async () => {
    setShowConfirm(false);
    const request = await deletePersons(user.uid)
    const response = await request.json()
    console.log(response);
    window.location = "/app"
  };

  return (
    <>
      {user && (
        <div className="clear-conversation-container">
          {showConfirm ? (
            <button
              className="clear-conversation-button"
              onClick={handleConfirm}
            >
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth={2}
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="trash-svg"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20 6 9 17l-5-5" />
              </svg>
              Confirm delete all Persons
            </button>
          ) : (
            <button
              className="clear-conversation-button"
              onClick={handleClearConversation}
            >
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth={2}
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="trash-svg"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2M10 11v6M14 11v6" />
              </svg>
              Delete all Persons
            </button>
          )}
        </div>
      )}
    </>
  );
}

export default ClearConversations;
