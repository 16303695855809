import "./NewPersonButton.css";
import React, { useState } from 'react';
import ModalPerson from './ModalPerson';
import { ref, push, get, set, update, increment } from "firebase/database";
import { createPerson } from "../Services/api";

const NewPersonButton = ({ handleNewChat, isLoading, app, db, user, personas, setConversation, messagesHistory, setMessagesHistory, getPersons}) => {
  const handleSubmit = async (formData) => {
    const calculate = {
      birthName: formData.fullName,
      birthDay: formData.dayOfBirth,
      birthMonth: formData.monthOfBirth,
      birthYear: formData.yearOfBirth,
      country: localStorage.getItem("country")
    }
    
    try {
      const response = await fetch('https://us-central1-optimal-moon-289301.cloudfunctions.net/n', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(calculate)
      });
  
      if (response.ok) {
          const jsonResponse = await response.json();
          const numbers = jsonResponse.numbers
          const person = {
            person: {
              auth_uid: user.uid,
              user_id: localStorage.getItem('user_id'),
              fullname: formData.fullName.trim(),
              birthday: `${formData.yearOfBirth.trim()}-${formData.monthOfBirth.trim().padStart(2, '0')}-${formData.dayOfBirth.trim().padStart(2, '0')}`,
              numerology: jsonResponse.numbers,
              hidden_agenda: numbers.agenda.number,
              life_path: numbers.lifepath.number,
              attitude: numbers.attitude.number,
              character: numbers.character.number,
              destiny: numbers.destiny.number,
              personality: numbers.personality.number,
              divine_purpose: numbers.purpose.number,
              soul_urge: numbers.soul.number,
              relation_type: formData.relationType
            }
        }
        try {
          const resp = await createPerson(person)
          const respCreate  = await resp.json()
          getPersons(user.uid)
          setConversation(user.uid, respCreate.data)
        } catch (e){
          console.log(e);
        }
      } else {
          throw new Error('Something went wrong with the server!');
      }
    } catch (error) {
        console.error('Error:', error);
    }  

    closeModal();
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
    
    <ModalPerson isOpen={isModalOpen} handleClose={closeModal} handleSubmit={handleSubmit} />
    <button
      className="new-chat-button"
      onClick={openModal}
      // Disabled while a message is loading
      // disabled={isLoading}
    >
      <span>+</span>Add Person
    </button>
    </>
    
  );
};

export default NewPersonButton;
