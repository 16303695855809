import React, { useState } from 'react';
import './PersonForm.css'; // Make sure to write appropriate CSS for the form

const PersonForm = ({ handleSubmit }) => {
  const [fullName, setFullName] = useState('');
  const [dayOfBirth, setDayOfBirth] = useState('');
  const [monthOfBirth, setMonthOfBirth] = useState('');
  const [yearOfBirth, setYearOfBirth] = useState('');
  const [relationType, setRelationType] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await handleSubmit({ fullName, dayOfBirth, monthOfBirth, yearOfBirth, relationType });
    setIsSubmitting(false);
  };

  const getMonth = (month) => {
    const m = {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December"
    };
    return m[month];
  }

  return (
    <div>
    <h1>New Persona</h1>
    <form className="birthdate-form" onSubmit={handleFormSubmit}>
      <input
        className="input-field"
        type="text"
        placeholder="Full Name (like your Birth Certificate)"
        value={fullName}
        onChange={(e) => setFullName(e.target.value)}
        required
      />
      <select
        className="select-field"
        value={dayOfBirth}
        onChange={(e) => setDayOfBirth(e.target.value)}
        required
      >
        <option value="">Day</option>
        {/* Generate days */}
        {[...Array(31)].map((_, index) => (
          <option key={index} value={index + 1}>
            {index + 1 }
          </option>
        ))}
      </select>
      <select
        className="select-field"
        value={monthOfBirth}
        onChange={(e) => setMonthOfBirth(e.target.value)}
        required
      >
        <option value="">Month</option>
        {/* You can replace these with actual month names */}
        {[...Array(12)].map((_, index) => (
          <option key={index} value={index + 1}>
            {getMonth(index + 1)}
          </option>
        ))}
      </select>
      <select
        className="select-field"
        value={yearOfBirth}
        onChange={(e) => setYearOfBirth(e.target.value)}
        required
      >
        <option value="">Year</option>
        {/* Generate years */}
        {[...Array(100)].map((_, index) => (
          <option key={index} value={new Date().getFullYear() - index}>
            {new Date().getFullYear() - index}
          </option>
        ))}
      </select>
      <select
        className="select-field"
        value={relationType}
        onChange={(e) => setRelationType(e.target.value)}
        required
      >
        <option value="">Select a relationship</option>
        <option value="girlfriend">Girlfriend</option>
        <option value="boyfriend">Boyfriend</option>
        <option value="brother">Brother</option>
        <option value="brother">Sister</option>
        <option value="spouse">Spouse</option>
        <option value="mother">Mother</option>
        <option value="father">Father</option>
        <option value="son">Son</option>
        <option value="daughter">Daughter</option>
        <option value="friend">Friend</option>
        <option value="cousin">Cousin</option>
        <option value="ex-partner">Ex-Partner (Ex-Boyfriend, Ex-Girlfriend, etc.)</option>
        <option value="fiance">Fiancé/Fiancée</option>
        <option value="partner">Partner</option>
        <option value="mentor">Mentor/ Mentee</option>
        <option value="colleague">Colleague</option>
        <option value="neighbor">Neighbor</option>
        <option value="grandchild">Grandchild</option>
        <option value="grandparent">Grandparent</option>
        <option value="acquaintance">Acquaintance</option>
        <option value="stepfamily">Stepfamily (Stepmother, Stepfather, etc.)</option>
        <option value="in-law">In-law (Mother-in-law, Father-in-law, etc.)</option>
        <option value="guardian">Guardian/Ward</option>
        <option value="roommate">Roommate</option>
        <option value="persona">Persona</option>
      </select>
      <button
          type="submit"
          className={`submit-button-persona ${isSubmitting ? 'submitting' : ''}`}
          disabled={isSubmitting} // Disable button while submitting
        >
          {isSubmitting ? 'Adding...' : 'Add Person'}
        </button>
    </form>
    </div>
    
  );
};

export default PersonForm;
