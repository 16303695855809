import React from 'react';
import ProfileForm from './ProfileForm';
import './ModalProfile.css';

const ModalProfile = ({ isOpen, handleClose, handleSubmit, userProfile}) => {
  if (!isOpen) return null;

  const conditionalHandleClose = (e) => {
    if (userProfile && userProfile.birthday) {
      handleClose();
    }
    e.stopPropagation();
  };

  return (
    <div className="modal-overlay" onClick={conditionalHandleClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <ProfileForm handleSubmit={handleSubmit} userProfile={userProfile} />
      </div>
    </div>
  );
};

export default ModalProfile;